import * as React from 'react';
import { OtherProjects } from '../components/shared/OtherProjects';
import ProjectInfo from '../components/shared/ProjectInfo';

export const StartWorkflowPage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "StartWorkflows",
    ProjectAbout : "Start es una aplicación web en la nube (azure) que permite a las empresas controlar sistémicamente sus procesos de negocio, para así poder entregar a sus clientes servicios de calidad predecible y oportunos.",
    ProjectFeatures: {
      First : "Permite controlar procesos mediante flujos de trabajo estandarizados basados en mejores prácticas",
      Second : "Entrega procesos estandarizados a los clientes de tal manera de aportar 'Mejores Prácticas'",
      Third : "Acceso perfilado a funciones del sistemas y aplicación de reglas de negocio propias de su negocio",
      Fourth : "Genera reportes y dashboards",
      Fifth : "Genera alertas y notificaciones para asegurar SLAs",
      Sixth : "Gestor de ficha de clientes, contratos y servicios",
      Seventh : "Módulo de tasación de prestación de servicios",
      Eighth : "Incliye API de integración (servicios web)"
    },
    ProjectRef: "",
    ProjectRefTitle: "Ver sitio web",
    ProjectSecondaryRef: "",
    ProjectSecondaryRefTitle: "",
    ProjectTags : "ASP.NET MVC3-BT3 | WCF-WF-EF | Azure",
    ProjectManager : {
      imageUrl: "../assets/team/cristian-sphere.png",
      imageInitials: 'CC',
      text: 'Cristián Carrión',
      secondaryText: 'Product Manager'
    }
  };

  return (
    <div>
      <div id='fullproject' className='start-workflow-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>
      <OtherProjects/>
    </div>
  );
};