import { Row, Col, Image } from "react-bootstrap";
import pancho from '../../assets/team/franciscop.jpg';
import Panel from './MyPanel';

export default function RightImageCard(props){

    return(
        <Row className="mt-5">
            <Col lg={6} className="mt-5">
                <div className="text-center">
                   
                    <h2>{props.info.title}</h2>
                    <hr></hr>
                    <span className="text-muted">{props.info.body}</span>
                    <br/><br/>
                    <Panel panelInfo={props.info.panelInfo} />
                    <br /><br />
                </div>
            </Col>
            <Col lg={6}>
                <Image fluid width={800} className='rounded' src={props.image}/>
            </Col>
        </Row>

    )
}

