import styles from "./NkSection.module.css";

const Section = ({ isBlueSection, children }) => {  
  const sectionClass = isBlueSection ? `${styles.nkSection} ${styles.blue}` : styles.nkSection

  return (
    <section className={sectionClass}>
      <div className={`container ${styles.nkSectionContainer}`}>
        <div className="row">{children}</div>
      </div>
    </section>
  );
};

export default Section;
