import * as React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import pancho from '../assets/team/franciscop.jpg';

import BasicCard from "../components/component-testing/BasicCard";
import LeftImageCard from "../components/component-testing/LeftImageCard";
import OurTeamVariant from '../components/component-testing/OurTeamVariant/OurTeamVariant';
import RightImageCard from '../components/component-testing/RightImageCard';
import SimpleTeachingBubble from '../components/component-testing/SimpleTeachingBubble';
import WideTeachingBubble from '../components/component-testing/WideTeachingBubble';

export const ComponentTesting: React.FunctionComponent = () => {
    const team = [
        {
            name: "Cristian Carrión",
            charge: "CO-FUNDADOR"
        },
        {
            name: "Luis Solar",
            charge: "CO-FUNDADOR"
        },
        {
            name: "Francisco Muñoz",
            charge: "INGENIERO DE SOFTWARE"
        },
        {
            name: "Pamela Saldivia",
            charge: "JEFE DE PROYECTO"
        },
        {
            name: "Francisco Pereira",
            charge: "ANALISTA PROGRAMADOR"
        },
        {
            name: "Juan Ibaceta",
            charge: "N/A"
        }

    ]
    const info = {
        title: "Lorem Ipsum",
        body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, sem eu sagittis ultrices, ipsum urna elementum augue, at finibus nisi ligula eu mi. Aliquam elementum id eros sit amet pellentesque. In ac luctus neque. Donec libero diam, consectetur at eleifend vitae, pretium ac turpis. Fusce augue neque, tempor vitae erat eu, sagittis euismod lacus. Duis id dapibus nulla. Fusce pulvinar massa erat, ac mollis nulla ornare id. Mauris quis feugiat dui. Integer vel ligula a orci mollis dapibus. Duis condimentum nec tellus id mollis. Aenean non augue sed ligula dignissim rhoncus in eget nisi. Etiam ultrices sed est vel euismod. Fusce lectus nibh, mattis a lectus a, tincidunt viverra nisl.",
        panelInfo1: 
        {
            header: "Right Lorem Ipsum",
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, sem eu sagittis ultrices, ipsum urna elementum augue, at finibus nisi ligula eu mi. Aliquam elementum id eros sit amet pellentesque. In ac luctus neque. Donec libero diam, consectetur at eleifend vitae, pretium ac turpis. Fusce augue neque, tempor vitae erat eu, sagittis euismod lacus. Duis id dapibus nulla. Fusce pulvinar massa erat, ac mollis nulla ornare id. Mauris quis feugiat dui. Integer vel ligula a orci mollis dapibus. Duis condimentum nec tellus id mollis. Aenean non augue sed ligula dignissim rhoncus in eget nisi. Etiam ultrices sed est vel euismod. Fusce lectus nibh, mattis a lectus a, tincidunt viverra nisl.",
            buttonMessage: "Más información",
        },
        panelInfo2: 
        {
            header: "Left Lorem Ipsum",
            body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus, sem eu sagittis ultrices, ipsum urna elementum augue, at finibus nisi ligula eu mi. Aliquam elementum id eros sit amet pellentesque. In ac luctus neque. Donec libero diam, consectetur at eleifend vitae, pretium ac turpis. Fusce augue neque, tempor vitae erat eu, sagittis euismod lacus. Duis id dapibus nulla. Fusce pulvinar massa erat, ac mollis nulla ornare id. Mauris quis feugiat dui. Integer vel ligula a orci mollis dapibus. Duis condimentum nec tellus id mollis. Aenean non augue sed ligula dignissim rhoncus in eget nisi. Etiam ultrices sed est vel euismod. Fusce lectus nibh, mattis a lectus a, tincidunt viverra nisl.",
            buttonMessage: "Más información",
        }
    }


  return (
    <Container>
        <h1 className='text-center'>Imagen izquierda y texto derecha</h1>
        <LeftImageCard image="https://wac-cdn.atlassian.com/dam/jcr:9f14cdc3-50b1-48de-ab0a-0e4b16816436/top.svg?cdnVersion=250" info={info}/>
        <hr/>
        <h1 className='text-center'>Imagen derecha y texto izquierda</h1>
        <RightImageCard image="https://wac-cdn.atlassian.com/dam/jcr:6a75dd4c-bb4f-45d2-8b23-ccd8949f21e0/Openbox-illustration.png?cdnVersion=250" info={info}/>
        <hr/>
        <h1 className='text-center'>Imagen arriba y texto abajo</h1>
        <BasicCard image="https://wac-cdn.atlassian.com/dam/jcr:9f14cdc3-50b1-48de-ab0a-0e4b16816436/top.svg?cdnVersion=250" info={info}/>
        <hr/>
        <h1 className='text-center'>Nuestro equipo</h1>
        <OurTeamVariant image="https://wac-cdn.atlassian.com/dam/jcr:18003964-321c-45f0-aede-27fbcd916740/Mike-hover.jpg?cdnVersion=250" team={team} info={info}/>
        <br/><br/>        
        <h1 className='text-center'>Burbujas</h1>
        <Row>
            <Col>
                <h1>Burbuja con botones</h1>
                <SimpleTeachingBubble buttonId="basicButton"/>
            </Col>
            <Col>
                <h1>Burbuja sin botones</h1>
                <WideTeachingBubble buttonId="wideButton"/>
            </Col>
        </Row>
        
        
    </Container>
  );
};