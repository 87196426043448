import NkSection from "../../shared/NkSection/NkSection";
import NkColumn from "../../shared/NkColumn/NkColumn";
import NkColumnImage from "../../shared/NkColumnImage/NkColumnImage";
import { DefaultButton } from "@fluentui/react";
import { Image } from "react-bootstrap";

const NkSectionCloud = () => {
  const redirecToMsProfile = () => {
    window
      .open(
        "https://appsource.microsoft.com/es-CL/marketplace/partner-dir/8d701b53-a4eb-4596-a051-963397aee19c/overview",
        "_blank"
      )
      .focus();
  };

  return (
    <NkSection isBlueSection={true}>
      <NkColumnImage img={"cloud-computing-nobg.png"} alt={"Cloud computing"} />       
          
          <NkColumn className="text-center">
              <br/>
              <h1>Nimok ahora es Azure Cloud Partner</h1>
              <Image fluid src="mspartnerwhitetranscomp.png" alt="Microsoft Silver Partner" />
      
           
        <DefaultButton
          text="Contáctanos vía Microsoft"
          onClick={() => redirecToMsProfile()}
        />
      </NkColumn>
    </NkSection>
  );
};

export default NkSectionCloud;
