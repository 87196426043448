import * as React from 'react';
import { PrimaryButton } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './HomeBanner.module.css'

export const HomeBanner: React.FunctionComponent = () => {
  return (
    <div className={styles.headerwrap}>
      <Container>
        <Row className='mt-5'>
          <Col lg={8}>
          </Col>
          <Col className='text-right'>
            <h2 id='color-white'>Nos encanta desarrollar software.</h2>            
          </Col>
        </Row>
      </Container>
    </div>    
  );
};