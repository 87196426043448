import * as React from 'react';
import { OurTeamBanner } from '../components/about-us/OurTeamBanner/OurTeamBanner';
import { OurTeam } from '../components/about-us/OurTeam/OurTeam';
import { Container, Row, Col } from 'react-bootstrap';

export const AboutUsPage: React.FunctionComponent = () => {
  return (
    <div>
      <Container>
      <Row className='text-center mt-3'>
          <h1> Nuestro equipo</h1>
          <OurTeam/>
        </Row>
      </Container>
    </div>
  );
};