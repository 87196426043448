import * as React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useBoolean} from '@fluentui/react-hooks';
import { DirectionalHint } from '@fluentui/react/lib/Callout';

export default function WideTeachingBubble(props){
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    return(
        <div>
            <DefaultButton
                id={props.buttonId}
                onClick={toggleTeachingBubbleVisible}
                text={teachingBubbleVisible ? 'Esconder' : 'Mostrar'}
                />

            {teachingBubbleVisible && (
                <TeachingBubble
                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                target={`#${props.buttonId}`}
                isWide={true}
                hasCloseButton={true}
                closeButtonAriaLabel="Close"
                onDismiss={toggleTeachingBubbleVisible}
                headline="Discover what’s trending around you"
                >
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, nulla, ipsum? Molestiae quis aliquam magni
                harum non?
                </TeachingBubble>
            )}
        </div>
    );

    
}
