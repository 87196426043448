import * as React from 'react';
import { OtherProjects } from '../components/shared/OtherProjects';
import ProjectInfo from '../components/shared/ProjectInfo';

export const ReworkMenuPage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "Carta de menú Rework MenuApp",
    ProjectAbout : "Rework es una empresa que quiere cambiar paradigmas en el mercado publicitario, introduciéndose en este mercado como un nuevo medio, innovador y por sobre todo interactivo, que pretende abarcar diferentes nichos de mercado con esta modalidad.",
    ProjectFeatures: {
      First : "Aplicación nativa iOS",
      Second : "Encuestas centralizadas en Azure",
      Third : "Personalización de templates para nuevos restaurantes"
    },
    ProjectRef: "http://www.rework.cl",
    ProjectRefTitle: "Ver sitio web",
    ProjectSecondaryRef: "",
    ProjectSecondaryRefTitle: "",
    ProjectTags : "Objective-C | XCode | Cocoa",
    ProjectManager : {
      imageUrl: "../assets/team/luis-sphere.png",
      imageInitials: 'LS',
      text: 'Luis Solar',
      secondaryText: 'Lead Developer'
    }
  };

  return (
    <div>
      <div id='fullproject' className='rework-menu-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>
      <OtherProjects/>
    </div>
  );
};