import { Container, Row, Col } from "react-bootstrap"
import { Icon } from '@fluentui/react/lib/Icon';

const HeartIcon = () => <Icon iconName="Heart" />;
const TestBeakerIcon = () => <Icon iconName="TestBeaker" />;
const LikeIcon = () => <Icon iconName="Like" />;

const WhoWeAreNarrative = () => {
    return(
        <Container className='mb-5'>
        <Row className='mb-3 mt-3'>
          <Col className='text-center'>
            <h2>Acerca de nosotros</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <h3>
              <HeartIcon />Nuestro propósito
            </h3>
            <p>NIMOK nació hace 12 años con la idea de proveer un espacio para compartir la pasión por hacer lo que más nos gusta: <b>Crear software sobresaliente</b>.</p>
          </Col>
          <Col lg={4}>
            <h3>
              <TestBeakerIcon/> ¿Cómo lo hacemos?
            </h3>
            <p>Utilizamos herramientas de software y gestión de desarrollo de vanguardia, nos perfeccionamos en el uso de tecnologías y nos aplicamos en utilizar buenas prácticas de ingeniería de software.</p>
          </Col>
          <Col lg={4}>
            <h3>
              <LikeIcon/> Nuestra oferta 
            </h3>
            <p>Entregamos productos de software  con altos estándares de calidad para proporcionar a nuestros clientes <b> una experiencia superior de servicio.</b> </p>
          </Col>
        </Row>
      </Container>
    )
}

export default WhoWeAreNarrative