 import * as React from 'react';
import { IPersonaSharedProps, Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import MemberInfo from '../MemberInfo';
import members from '../../../utils/teamMembers';
import styles from './OurTeam.module.css';

export const OurTeam: React.FunctionComponent = () => {  
    const [renderDetails, updateRenderDetails] = React.useState(true);    

    return (
        <Container className='mt-3'>
        <Tab.Container defaultActiveKey={members[0].Name}>
            <Row className='mb-3'>
                <Container className='centered'>
                    <Row className='col-md-8 mb-2' >
                        {members.map(member => 
                            <Col key={member.Name} lg={4} md={6} xs={6} className={styles.teamMemberSelector}>
                                <Nav>
                                    <Nav.Item>
                                    <Nav.Link eventKey={member.Name}>
                                        <Persona
                                        {...member.Props}
                                        size={PersonaSize.size48}
                                        hidePersonaDetails={!renderDetails}
                                        imageAlt={member.Name}
                                        />
                                    </Nav.Link>
                                    </Nav.Item>
                                </Nav>                    
                            </Col> )}
                    </Row>
                </Container>
                <Col>                
                </Col>
            </Row>
            <Tab.Content>
                {members.map(member => <Tab.Pane key={member.Name} eventKey={member.Name}>
                        <MemberInfo member={member.Persona}/>
                    </Tab.Pane> )}                    
            </Tab.Content>
        </Tab.Container>        

        
        </Container>
  );
};