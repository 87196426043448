import { Container, Row, Col, Card } from "react-bootstrap";
import "./OurTeamVariant.css";

export default function OurTeamVariant(props){
    
    return(
        <Row>
            <h1 className='text-center section-title pb-5'>Sobre Nimok</h1>
            <Col>
                <Row>
                    {Array.from(props.team).filter((member, idx) => idx < 6).map((member, idx) => (
                        <Col key={idx}>
                            <Card className="border-0 px-1">
                                <Card.Img className="member-img-size" src={props.image} />
                                <Card.Body>
                                    <div className="eyebrows-wrapper">
                                        <Card.Title>{member.name}</Card.Title>
                                        <span className="eyebrows">{member.charge}</span>
                                    </div>
                                    <hr/>
                                <Row>

                                {Array.from(member.titles).map((title, idx) => (
                                     <span className="eyebrows-minor" key={idx}>{title}</span>
                                ))}                               
                                </Row>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                    ))}
                </Row>
                <hr/>
                <div>
                    <div className="text-center mb-3 section-pharagraph">
                        {props.info.firstPharagraph}
                    </div>
                    <div className="text-center mb-3 section-pharagraph">
                        {props.info.secondPharagraph}
                    </div>
                    <div className="text-center mb-3 section-pharagraph">
                        {props.info.thirdPharagraph}
                    </div>
                </div>
            </Col>
        </Row>
    )
}

