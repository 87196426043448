import startWorkflow from '../assets/portfolio/start-2.png'
import catalogoCru from '../assets/portfolio/catalogocru-1.png'
import apoquindo from '../assets/portfolio/apoquindo5400-1.png'
import reworkMenu from '../assets/portfolio/rework-terrazaw-small.png'
import vistaHome from '../assets/portfolio/project-vistahome.png'
import providencia from '../assets/portfolio/project-providencia-small2.jpg'

const workItems = [
    {
        to: "/start-workflow",
        ariaLabel: 'StartWorflows. Cristián Carrión - Product Manager.',
        title: "StartWorkflows",
        imageSrc: startWorkflow,
        activity: "Product Manager",
        people: [{ name: 'Cristián Carrión', profileImageSrc: "cristian-sphere.png", initials: 'CC' }]
    },
    {
        to: "/catalogo-cru",
        ariaLabel: 'Catálogo corporativo. Cristián Carrión - Product Manager.',
        title: "Catálogo corporativo",
        imageSrc: catalogoCru,
        activity: "Product Manager",
        people: [{ name: 'Cristián Carrión', profileImageSrc: "cristian-sphere.png", initials: 'CC' }]
    },
    {
        to: "/apoquindo",
        ariaLabel: 'Brochure Inmobiliario. Cristián Carrión - Product Manager.',
        title: "Brochure Inmobiliario",
        imageSrc: apoquindo,
        activity: "Product Manager",
        people: [{ name: 'Cristián Carrión', profileImageSrc: "cristian-sphere.png", initials: 'CC' }]
    },
    {
        to: "/rework-menu",
        ariaLabel: 'Rework MenuApp. Luis Solar - Lead Developer.',
        title: "Rework MenuApp",
        imageSrc: reworkMenu,
        activity: "Lead Developer",
        people: [{ name: 'Luis Solar', profileImageSrc: "luis-sphere.png", initials: 'LS' }]
    },
    {
        to: "/vista-home",
        ariaLabel: 'Vista Home. Cristián Carrión - Product Manager.',
        title: "Vista Home",
        imageSrc: vistaHome,
        activity: "Product Manager",
        people: [{ name: 'Cristián Carrión', profileImageSrc: "cristian-sphere.png", initials: 'CC' }]
    },
    {
        to: "/providencia",
        ariaLabel: 'Servicios Municipalidad de Providencia. Cristián Carrión - Product Manager.',
        title: "Municipalidad de Providencia",
        imageSrc: providencia,
        activity: "Product Manager",
        people: [{ name: 'Cristián Carrión', profileImageSrc: "cristian-sphere.png", initials: 'CC' }]
    }
]

export default workItems