import * as React from 'react';
import { Stack } from '@fluentui/react';
import { HomeBanner } from '../components/home/HomeBanner/HomeBanner';
import { LatestWork } from '../components/shared/LatestWork';
import { OurClients } from '../components/home/OurClients';
import { CustomerOpinion } from '../components/home/CustomerOpinion';
import { QuoteBanner } from '../components/home/QuoteBanner';
import { Row, Col } from 'react-bootstrap';
import WhoWeAreNarrative from '../components/shared/WhoWeAreNarrative/WhoWeAreNarrative';

export const WhoWeAre: React.FunctionComponent = () => {
  return (
    <Row>
      <Col>
        <Stack>
          <HomeBanner />
        </Stack>        
              <WhoWeAreNarrative />
        <QuoteBanner />   
        <OurClients />
        
      </Col>
    </Row>
  );
};