import * as React from "react";
import NkSectionCloud from "../components/home/NkSectionCloud/NkSectionCloud";
import NkSectionVideo from "../components/home/NkSectionVideo/NkSectionVideo";
import { CardComponents } from "./CardComponents";
import { NkCertifications } from "../components/home/NkCertifications/NkCertifications";

export const HomePage: React.FunctionComponent = () => {
  return (
    <>
          <NkSectionVideo />
          <NkSectionCloud /> 
          <CardComponents />
          <NkCertifications />
    </>
  );
};
