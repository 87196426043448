import React from 'react';
import { Separator } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';
import { Icon } from '@fluentui/react/lib/Icon';
import { Stack } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';

const CoffeeScriptIcon = () => <Icon iconName="CoffeeScript" />;
const WindowsLogoIcon = () => <Icon iconName="WindowsLogo" />;
const ReadingModeIcon = () => <Icon iconName="ReadingMode" />;
const MyMoviesTVIcon = () => <Icon iconName="MyMoviesTV" />;
const TestBeakerIcon = () => <Icon iconName="TestBeaker" />;
const WorldIcon = () => <Icon iconName="World" />;
const MoreSportsIcon = () => <Icon iconName="MoreSports" />;
const LinkedInLogoIcon = () => <Icon iconName="LinkedInLogo" />;
const MailSolidIcon = () => <Icon iconName="MailSolid" />;
const GameIcon = () => <Icon iconName='Game' />
const TVMonitor = () => <Icon iconName='TVMonitor' />

const stackStylesDarkBackground = {
    root: {
      background: DefaultPalette.neutralDark,
      padding: 10,
    },
};

const stackItemStyles = {
    root: {      
      color: DefaultPalette.white,
      padding: 5,
    },
};

const MemberInfo = ({member}) => {
    return (
        <div className='mb-2'>
            <Row>
                <Col>
                    <Stack styles={stackStylesDarkBackground} >
                        <Stack.Item styles={stackItemStyles}>
                            <h2 id='color-white'>{member.MemberName}</h2>
                            <Separator/>
                            <h4 id='color-white'>{member.MemberTitle}</h4>
                        </Stack.Item>            
                    </Stack>
                </Col>
            </Row>
            <br></br>
            <Container>
            <Row>
                <CoffeeScriptIcon/>
                <h2>{member.MemberQuote}</h2>
                <Separator/>
            </Row>
            <Row>
                <Col lg={4}>
                    <h5>{member.MemberMoreAbout}</h5>
                    <p>{member.MemberResume}</p>
                    <LinkedInLogoIcon/> <a href={member.MemberLinkedinLink}>{member.MemberLinkedinName}</a> | <MailSolidIcon/> {member.MemberEmail} 
                </Col>
                <Col lg={4}>
                    <img className='img-fluid' src={member.MemberImageUrl} alt={member.MemberName}/>
                </Col>
                <Col lg={4}>
                    <h5>Conoce más</h5>
                    <div className='row'>
                        {member.MemberIsUsing && <span className='mx-auto mt-1' title='Equipo'><WindowsLogoIcon/> {member.MemberIsUsing}</span>}
                        {member.MemberIsDrinking && <span className='mx-auto mt-1' title='Bebestible favorito'><CoffeeScriptIcon/> {member.MemberIsDrinking}</span>}
                        {member.MemberIsReading && <span className='mx-auto mt-1' title='Leyendo'><ReadingModeIcon/> {member.MemberIsReading}</span>}
                        {member.MemberFavoriteMovie && <span className='mx-auto mt-1' title='Película favorita'><MyMoviesTVIcon/> {member.MemberFavoriteMovie}</span>}
                        {member.MemberWantedToBe && <span className='mx-auto mt-1' title='De niño/a quería ser...'><TestBeakerIcon/> {member.MemberWantedToBe}</span>}
                        {member.MemberPlaceInTheWorld && <span className='mx-auto mt-1' title='Lugar'><WorldIcon/> {member.MemberPlaceInTheWorld}</span>}
                        {member.MemberFavoriteSport && <span className='mx-auto mt-1' title='Deporte favorito'><MoreSportsIcon/> {member.MemberFavoriteSport}</span>}
                        {member.MemberFavoriteVideogame && <span className='mx-auto mt-1' title='Videojuego favorito'><GameIcon/> {member.MemberFavoriteVideogame}</span>}  
                        {member.MemberFavoriteTVShow && <span className='mx-auto mt-1' title='Show de TV favorito'><TVMonitor/> {member.MemberFavoriteTVShow}</span>}                    
                    </div>
                    
                </Col>
            </Row>
            </Container>
        </div>
    );
};

export default MemberInfo;