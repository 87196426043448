import React from 'react';
import { Separator } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { Icon } from '@fluentui/react/lib/Icon';

const LinkIcon = () => <Icon iconName="Link" />;
const TagIcon = () => <Icon iconName="Tag" />;

const ProjectInfo = (props) => {
    function RenderOptionalSection(props){
        if (props.project.ProjectOptionalTitle !== ""){
            return (
            <div>
                <h5>{props.project.ProjectOptionalTitle}</h5>
                <p>{props.project.ProjectOptionalDescription}</p>
            </div>
            )
        }
    }

    function RenderSecondaryRef(props){
        if (props.project.ProjectSecondaryRefTitle !== ""){
            return(
                <div>
                    <LinkIcon/> <a href={props.project.ProjectSecondaryRef}>{props.project.ProjectSecondaryRefTitle}</a><br/>
                    <br/>
                </div>
            )
        }
    }

    return (
        <Container>
            <Row>
                <h2 className='text-center mt-4'>{props.project.ProjectName}</h2>
                <Separator/>
                <Container>
                    <Row>
                        <Col lg={4}>
                            <h5>Acerca del proyecto</h5>   
                            <p>{props.project.ProjectAbout}</p>
                            {RenderOptionalSection(props)}
                        </Col>
                        <Col lg={4}>
                            <h5>Características principales</h5>
                            <ul>
                                {Object.keys(props.project.ProjectFeatures).map(function(key) {
                                    return <li key={key}>{props.project.ProjectFeatures[key]}</li>;
                                })}
                            </ul>
                        </Col>
                        <Col lg={4}>
                            <h5>Información del proyecto</h5>
                            <LinkIcon/> <a href={props.project.ProjectRef}>{props.project.ProjectRefTitle}</a><br/>
                            {RenderSecondaryRef(props)}
                            <TagIcon/> {props.project.ProjectTags}<br/>
                            
                            <br></br>
                            <Persona
                            {...props.project.ProjectManager}
                            size={PersonaSize.size40}
                            imageAlt="Cristián Carrión"
                            />
                        </Col>
                    </Row>
                </Container>
            </Row>
        </Container>        
    );
};

export default ProjectInfo;