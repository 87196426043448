const members = [
    {
        Name: "Cristian Carrion",
        Persona: {
            MemberTabPaneId: "tab_ccarrion",
            MemberName: "Cristián Carrión",
            MemberTitle: "Azure Administrator | Product Owner",
            MemberQuote: "\"Investigar y probar nuevos usos para la tecnología.\"",
            MemberQuoteIcon: "fa-coffee",
            MemberMoreAbout: "Más acerca de Cristián.",
            MemberResume: "Desde mi infancia me fascinó la computación y ahora puedo decir que estoy haciendo lo que más me gusta.",
            MemberLinkedinLink: "http://www.linkedin.com/pub/cristian-carrion/6/826/71a",
            MemberLinkedinName: "@ccarrion",
            MemberEmail: "ccarrion@nimok.com",
            MemberImageUrl: "cristian.jpg",
            MemberIsUsing: "Surface Laptop 4",
            MemberIsDrinking: "Ristretto",
            MemberIsDrinkingIcon: "fa-coffee",
            MemberIsReading: "CODE Magazine NET 6.0",
            MemberFavoriteMovie: "Sueños de Fuga",
            MemberWantedToBe: "Lo que soy ahora",
            MemberPlaceInTheWorld: "Frutillar",
            MemberFavoriteSport: "Ciclismo",
        },
        Props: {
            imageUrl: "cristian-sphere.png",
            imageInitials: 'CC',
            text: 'Cristian Carrión',
            secondaryText: 'Azure Administrator',
            tertiaryText: 'Product Owmer',
            optionalText: 'CoFundador'
        }
    },
    {
        Name: "Luis Solar",
        Persona: {
            MemberTabPaneId: "tab_lsolar",
            MemberName: "Luis Solar",
            MemberTitle: "Co-Fundador | Arquitecto | Desarrollador",
            MemberQuote: "\"Hacer lo que te gusta con pasión y dedicación.\"",
            MemberQuoteIcon: "fa-coffee",
            MemberMoreAbout: "Más acerca de Luis.",
            MemberResume: "Ingeniero de software con más de 15 años de experiencia en creación, diseño y construcción de software. Su primera computadora fue un Sinclair ZX81, el comienzo de Nimok.",
            MemberLinkedinLink: "http://www.linkedin.com/pub/luis-solar/5/53a/339",
            MemberLinkedinName: "@solarluis",
            MemberEmail: "lsolar@nimok.com",
            MemberImageUrl: "luis.jpg",
            MemberIsUsing: "Samsung Series Chronos 7 y MBP",
            MemberIsDrinking: "Espresso macchiato",
            MemberIsDrinkingIcon: "fa-coffee",
            MemberIsReading: "Crónicas de Joaquín Edwards Bello",
            MemberFavoriteMovie: "Die hard 1",
            MemberWantedToBe: "Inventor",
            MemberPlaceInTheWorld: "Con mi familia",
            MemberFavoriteSport: "Ciclismo 101",            
        },
        Props: {
            imageUrl: "luis-sphere.png",
            imageInitials: 'LS',
            text: 'Luis Solar',
            secondaryText: 'Co-fundador',
            tertiaryText: 'Arquitecto',
            optionalText: 'Desarrollador',
        }
    },
    {
        Name: "Francisco Astorga",
        Persona: {
            MemberTabPaneId: "tab_fastorga",
            MemberName: "Francisco Astorga",
            MemberTitle: "Azure Developer Associate | DevOps Engineer | Senior Software Engineer",
            MemberQuote: "\"Sic Parvis Magna\"",
            MemberQuoteIcon: "fa-film",
            MemberMoreAbout: "Más acerca de Francisco.",
            MemberResume: "Desarrollador de software senior, músico y actor. Desde pequeño apasionado por la tecnología y las artes; hoy desempeñándose en ambas. Siempre aprendiendo, transmitiendo conocimiento y anhelando entregar tanto como la vida me permita.",
            MemberLinkedinLink: "https://www.linkedin.com/in/fastorga-dev/",
            MemberLinkedinName: "Francisco Astorga",
            MemberEmail: "fastorga@nimok.com",
            MemberImageUrl: "fa2.jpg",
            MemberIsUsing: "Custom Gaming PC | Windows 11",
            MemberIsDrinkingIcon: "fa-glass",
            MemberIsDrinking: "Agua Benedictino",
            MemberIsReading: "Guiones de obras de teatro",
            MemberFavoriteMovie: "La La Land",
            MemberWantedToBe: "Músico",
            MemberPlaceInTheWorld: "Midgar",
            MemberFavoriteSport: "Lucha Libre",
            MemberFavoriteVideogame: "Death Stranding",
            MemberFavoriteTVShow: "Jojo's Bizarre Adventure"
        },
        Props: {
            imageUrl: "fa2.jpg",
            imageInitials: 'FA',
            text: 'Francisco Astorga',
            secondaryText: 'Azure Developer Associate',
            tertiaryText: 'DevOps Engineer',
            optionalText: 'C# NET Developer'
        }
    },
    {
        Name: "Pamela Saldivia",
        Persona: {
            MemberTabPaneId: "tab_psaldivia",
            MemberName: "Pamela Saldivia",
            MemberTitle: "Data Engineer",
            MemberQuote: "\"Disfrutar cada instante de la vida, sin apuro.\"",
            MemberQuoteIcon: "fa-film",
            MemberMoreAbout: "Más acerca de Pamela.",
            MemberResume: "Ingenieria Civil en Informática, Magister en TI,  más de 15 años de experiencia desarrollo de software",
            MemberLinkedinLink: "",
            MemberLinkedinName: "",
            MemberEmail: "psaldivia@nimok.com",
            MemberImageUrl: "pamela.png",
            MemberIsUsing: "Mac Mini",
            MemberIsDrinkingIcon: "fa-glass",
            MemberIsDrinking: "Latte vainilla",
            MemberIsReading: "Entre libros",
            MemberFavoriteMovie: "Bajo el sol de Toscana",
            MemberWantedToBe: "Profesora de Matemáticas",
            MemberPlaceInTheWorld: "Mi Casa",
            MemberFavoriteSport: "Pilates",
        },
        Props: {
            imageUrl: "pamela.png",
            imageInitials: 'PS',
            text: 'Pamela Saldivia',
            secondaryText: 'Data Engineer',
            tertiaryText: 'Scrum Master',
            optionalText: 'Business Analyst'
        }
        },
    {
        Name: "Christian Sepulveda",
        Persona: {
            MemberTabPaneId: "tab_csv",
            MemberName: "Christian Sepulveda",
            MemberTitle: "C# NET Developer",
            MemberQuote: "\"There are no secrets in life, just hidden truths that lie beneath the surface.\"",
            MemberQuoteIcon: "fa-film",
            MemberMoreAbout: "Más acerca de Christian.",
            MemberResume: "Ingeniero Civil en Informática apasionado por el desarrollo de software y videojuegos. Si no está programando, está jugando o durmiendo. Fanático de la Fórmula 1.",
            MemberLinkedinLink: "https://www.linkedin.com/in/christian-sepulveda/",
            MemberLinkedinName: "@csv",
            MemberEmail: "csepulveda@nimok.com",
            MemberImageUrl: "csv-karting.jpg",
            MemberIsUsing: "Gaming Computer",
            MemberIsDrinkingIcon: "fa-glass",
            MemberIsDrinking: "Vanilla Latte",
            MemberIsReading: "Darkly Dreaming Dexter",
            MemberFavoriteMovie: "Back to the Future",
            MemberWantedToBe: "Piloto comercial",
            MemberPlaceInTheWorld: "New Zealand",
            MemberFavoriteSport: "Tennis",
        },
        Props: {
            imageUrl: "csv.jpeg",
            imageInitials: 'CSV',
            text: 'Christian Sepulveda',
            secondaryText: 'C# NET Developer',
        }
        },{
        Name: "Jeremy Castro",
        Persona: {
            MemberTabPaneId: "tab_jcastro",
            MemberName: "Jeremy Castro",
            MemberTitle: "C# NET Developer",
            MemberQuote: "\"Siempre hay que tratar de ser una mejor versión de si mismo, pero nunca creerse mejor que el resto.\"",
            MemberQuoteIcon: "fa-film",
            MemberMoreAbout: "Más acerca de Jeremy",
            MemberResume: "Ingenierio Civil en Informática apasionado del desarrollo de software. Fanático de los videojuegos, cubos de rubick, motorsport y el simracing.",
            MemberLinkedinLink: "",
            MemberLinkedinName: "",
            MemberEmail: "",
            MemberImageUrl: "Jeremy_low.jpg",
            MemberIsUsing: "Gaming Computer",
            MemberIsDrinkingIcon: "fa-glass",
            MemberIsDrinking: "Flat White",
            MemberIsReading: "The Witcher Saga",
            MemberFavoriteMovie: "John Wick",
            MemberWantedToBe: "Fotografía",
            MemberPlaceInTheWorld: "Austria",
            MemberFavoriteSport: "Tenis",
        },
        Props: {
            imageUrl: "Jeremy.jpg",
            imageInitials: 'JS',
            text: 'Jeremy Castro',
            secondaryText: 'C# NET Developer',
        }
    }        
]

export default members