import * as React from 'react';
import { OtherProjects } from '../components/shared/OtherProjects';
import ProjectInfo from '../components/shared/ProjectInfo';

export const VistaHomePage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "Vista Home",
    ProjectAbout : "Imagina la posibilidad de que pudieras conocer tu futura vivienda y todos sus atributos a través de un recorrido virtual, con la posibilidad de ver diversas orientaciones y decoraciones para tu hogar desde tu iPad y desde este mismo soporte cotizar y reservar tu futura propiedad sin la necesidad de visitar el proyecto. ¿Que cómodo no?<br/>No te lo imagines... vívelo.",
    ProjectFeatures: {
      First : "Aplicación nativa iOS.",
      Second : "Navegación dentro de las del inmueble en 360°",
      Third : "Acceso a habitaciones y cuartos con un toque en la puerta",
      Fourth : "Visualización del videos e imágenes del proyecto",
      Fifth : "Permite cotizar y solicitar atención de un ejecutivo"
    },
    ProjectRef: "https://itunes.apple.com/ar/artist/nimok/id568668934",
    ProjectRefTitle: "App Store",
    ProjectSecondaryRef: "",
    ProjectSecondaryRefTitle: "",
    ProjectTags : "iOS5+ | Objective-C | CocoaTouch",
    ProjectManager : {
      imageUrl: "../assets/team/cristian-sphere.png",
      imageInitials: 'CC',
      text: 'Cristián Carrión',
      secondaryText: 'Product Manager'
    }
  };

  return (
    <div>
      <div id='fullproject' className='vista-home-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>
      <OtherProjects/>
    </div>
  );
};