import * as React from 'react';
import { Container } from 'react-bootstrap';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { useBoolean, useId } from '@fluentui/react-hooks';
import SimpleTeachingBubble from '../components/component-testing/SimpleTeachingBubble';
import WideTeachingBubble from '../components/component-testing/WideTeachingBubble';

export const TeachingBubbleComponents: React.FunctionComponent = () => {
    const buttonId2 = useId('targetButton2');
    
    const [teachingBubbleWide, { toggle: toggleTeachingBubbleWide }] = useBoolean(false);

    
    return (
        <Container>
            <div>
                <h1 className='text-center'>Burbuja básica</h1>
                <SimpleTeachingBubble buttonId="basicButton"/>
                <hr/>
                <h1 className='text-center'>Burbuja sin botones</h1>
                <WideTeachingBubble buttonId="wideButton"/>
            </div>
            <br/><br/>

        </Container>
      
    );
};

const examplePrimaryButtonProps: IButtonProps = {
  children: 'Try it out',
};

