import "/node_modules/video-react/dist/video-react.css";
import { Player, ControlBar } from "video-react";
import styles from './NkSectionVideo.module.css'

const NkSectionVideo = () => {
  return (
    <div className={styles.videoContainer}>      
      <div className={styles.video}>
        <Player autoPlay={true} fluid={true} preload="auto"  muted={true} loop={true} >
          <source src="nkweb video puzzle.mp4" />
          <ControlBar autoHide={true}/>
        </Player>
      </div>
    </div>

  );
};
export default NkSectionVideo;
