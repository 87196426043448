import { Card } from "react-bootstrap";

export default function BasicCard(props){

    return(
        <Card className="border-0" style={{ width: '30rem' }}>
            <Card.Img variant="top" src={props.image} />
            <Card.Body>
                <div className="justify-text">
                    <h1>{props.info.title}</h1>
                    <span className="text-muted">{props.info.body}</span>
                </div>
            </Card.Body>
        </Card>
    )
}

