import * as React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useBoolean} from '@fluentui/react-hooks';

export default function SimpleTeachingBubble(props){
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const examplePrimaryButtonProps = {
        children: 'Try it out',
      };

    const exampleSecondaryButtonProps = React.useMemo(
        () => ({
          children: 'Maybe later',
          onClick: toggleTeachingBubbleVisible,
        }),
        [toggleTeachingBubbleVisible],
      );

    return(
        <div>
            <DefaultButton
                id={props.buttonId}
                onClick={toggleTeachingBubbleVisible}
                text={teachingBubbleVisible ? 'Esconder' : 'Mostrar'}
                />

            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${props.buttonId}`}
                    primaryButtonProps={examplePrimaryButtonProps}
                    secondaryButtonProps={exampleSecondaryButtonProps}
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Discover what’s trending around you"
                >
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facere, nulla, ipsum? Molestiae quis aliquam magni
                    harum non?
                </TeachingBubble>
            )}
        </div>
    );

    
}