import * as React from 'react';
import { PrimaryButton } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './NkCertifications.module.css'

export const NkCertifications: React.FunctionComponent = () => {
  return (
    <div className={styles.headerwrap}>
      <Container>
        <Row className='mt-4'>
          <Col lg={8}>
          </Col>
          <Col className='text-center'>
                  
          </Col>
        </Row>
      </Container>
    </div>    
  );
};