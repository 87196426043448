import styles from "./NkColumn.module.css";

const NkColumn = (props) => {
  const { className, children } = props;

  return (
    <div className="col-sm-12 col-md-6">
      <div className={`${styles.nkColumn} ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default NkColumn;
