import * as React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { propTypes } from 'react-bootstrap/esm/Image';

const buttonStyles = { root: { marginRight: 8 } };

export default function MyPanel(props){
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
          Cerrar
        </PrimaryButton>
      </div>
    ),
    [dismissPanel],
  );

  return (
    <div>
      <DefaultButton text={props.panelInfo.buttonMessage} onClick={openPanel} />
      <Panel
        isOpen={isOpen}
        onDismiss={dismissPanel}
        headerText={props.panelInfo.header}
        closeButtonAriaLabel="Cerrar"
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
              <p>
               <br/>
                 <li> {props.panelInfo.body} </li>
              </p>
            
      </Panel>
    </div>
  );
};
