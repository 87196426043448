import * as React from 'react';
import { Separator } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';
import LinkedDocumentCard from './LinkedDocumentCard';
import workItems from '../../utils/portfolio-items'

export const OtherProjects: React.FunctionComponent = () => {    
    return (
        <Container>
            <Row className='mt-3 mb-3'>
                <Col className='text-center'>
                    <Separator />
                    <h2>Otros proyectos</h2>
                </Col>
            </Row>
            <Row className='mb-5'>
                {workItems.map(item =>
                    <Col key={item.title} lg={4} md={6} xs={12}>
                        <LinkedDocumentCard item={item} />
                    </Col>)}
            </Row>
        </Container>
    );
};
