import * as React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import OurTeamVariant from '../components/component-testing/OurTeamVariant/OurTeamVariant';

export const OurTeamVariantPage: React.FunctionComponent = () => {
    const team = [
        {
            name: "Cristian Carrión",
            charge: "CO-FUNDADOR",
            titles: ["Azure Developer Asociatte", "Desarrollador Senior", "NET C# Full stack", "Scrum Certified"] 
        },
        {
            name: "Luis Solar",
            charge: "CO-FUNDADOR",
            titles: ["Azure Administrator Asociatte", "Product Owner Certified", "Itil Foundation"] 
        },
        {
            name: "Francisco Muñoz",
            charge: "INGENIERO DE SOFTWARE",
            titles: ["Azure Developer Asociatte", "AWS Architect", "Arquitecto de soluciones", "Scrum Certified", "NET C#"] 
        },
        {
            name: "Pamela Saldivia",
            charge: "JEFE DE PROYECTO",
            titles: ["Azure Developer Asociatte", "AWS Architect", "Arquitecto de soluciones", "Scrum Certified", "NET C#"] 
        }

    ]
    const info = {
        firstPharagraph: "Fundada el año 2011, somos una empresa de profesionales certificados que entrega soluciones de software.",
        secondPharagraph: "Nuestro modelo de negocio se basa en ser un partner tecnológico de nuestros clientes, desarrollando software y entregando servicios profesionales en la nube de Microsoft Azure.",
        thirdPharagraph: "Valoramos la simplicidad para aportar valor a nuestros clientes."
    }
  return (
    <Container>
        <OurTeamVariant image="https://wac-cdn.atlassian.com/dam/jcr:18003964-321c-45f0-aede-27fbcd916740/Mike-hover.jpg?cdnVersion=250" team={team} info={info}/> 
        <br/><br/>
    </Container>
  );
};