import * as React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import BasicCard from "../components/component-testing/BasicCard";
import LeftImageCard from "../components/component-testing/LeftImageCard";
import RightImageCard from '../components/component-testing/RightImageCard';

export const CardComponents: React.FunctionComponent = () => {
    const info1 = {
        title: "Consultoría y diseño de soluciones en Azure",
        body: "Ayudamos a diseñar y elaborar los planes de adopción de los servicios que ofrece Azure, como tambien soluciones de seguridad y arquitecturas  para la implementación de soluciones modernas",
        panelInfo: 
        {
            header: "Seguridad, Administración de identidades y arquitectura",
            body: "Simplificar y securitizar sus aplicativos con inicio de sesión único,políticas de compliance, gestión de dispositivos y resguardo de información. Diseñar arquitecturas que permiten la interoperación con ambientes hibridos y entre nubes. Además con la integración a PowerPlatform,PowerBI, Power Automate potenciará sus soluciones a otra escala. ",
            buttonMessage: "Más información",
        }
       
    }

    const info2 = {
        title: "Azure DevOPS - Integración Continua ",
        body: "Como desarrolladores de software sabemos la importancia de la integración continua que cubra la operación, testing y seguridad para nuestras aplicaciones. Entregamos el servicio para desplegar aplicaciones y recursos tanto en la nube de azure, on-premise y otras nubes. Utilizando todas las herramientas que nos entrega Azure para implementar eficientemente el proceso de entrega continua.",
        panelInfo:
        {
            header: "DevOps Integración Continua",
            body: "Azure DevOps permite que los despliegues de aplicativos e infraestructura, pruebas automatizadas, pruebas de seguridad sean un problema menos. Logrará que sus equipos se concentren en el incremento de valor en cada ciclo de desarrollo y no en la operación.",
            buttonMessage: "Más información",
        }
    }

    const info3 = {
        title: "Desarrollo moderno de aplicaciones",
        body: "La servicios de Azure permiten crear aplicaciones modernas utilizando cientos de recursos que permiten un desarrollo más rapido, eficiente en costos y seguro. El uso de computo serverless, logics apps, data factory, workflows, colas, event hub entre otros. Le permitiran marcar una diferencia en la rapidez de desarrollo, seguridad y escalabilidad.",
        panelInfo:
        {
            header: "DevOps Integración Continua",
            body: "El uso de los servicios PaaS proveen velocidad,escalibilidad para llegar conectar y consumir recursos de Azure. Esto sumado al  MarketPlace de Azure le permitirá que sus applicaciones sean más faciles de desarrollar concentrándose en el valor que aporta a su negocio.",
            buttonMessage: "Más información",
        }
    }

  return (
    <div style={ {backgroundColor: 'white'}}>
        <Container>
            <h1 className='text-center'></h1>
                <RightImageCard with={600} image="cubos apilados.jpg" info={info1}/>
        
            
            <h1 className='text-center'></h1>
                <LeftImageCard image="lego-cloud@0.5x.png" info={info3} />


            <h1 className='text-center'></h1>
                <RightImageCard image="devops test plan.jpg" info={info2} />
        </Container>
    </div>
    
  );
};  