import * as React from 'react';
import ProjectInfo from '../components/shared/ProjectInfo';
import { OtherProjects } from '../components/shared/OtherProjects';

export const ApoquindoPage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "Apoquindo 5400",
    ProjectAbout : "Esta aplicación está pensada para grandes empresas que buscan en Chile el mejor edificio de oficinas para desarrollar su negocio o actividad comercial. Permite conocer en detalle el Edificio de Oficinas Apoquindo 5400, quienes han participado en su diseño y construcción, dónde se encuentra ubicado y sus principales características.",
    ProjectFeatures: {
      First : "Aplicación nativa iOS.",
      Second : "Publicación de páginas con visualización interactiva de gráficos estádisticos",
      Third : "Navegación fluida sobre contenido digital",
      Fourth : "Acceso a resumen de páginas (thumbnails)",
      Fifth : "Distribuición privada"
    },
    ProjectRef: "https://apps.apple.com/ar/app/apoquindo-5400/id568668931",
    ProjectRefTitle: "App Store",
    ProjectSecondaryRef: "",
    ProjectSecondaryRefTitle: "",
    ProjectTags : "Phone Gap | HTML5 | SenchaTouch",
    ProjectManager : {
      imageUrl: "../assets/team/cristian-sphere.png",
      imageInitials: 'CC',
      text: 'Cristián Carrión',
      secondaryText: 'Product Manager'
    }
  };
  return (
    <div>
      <div id='fullproject' className='apoquindo-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>     
      <OtherProjects/> 
    </div>
  );
};