import React from 'react';
import { Footer } from './Footer/Footer';
import Navigation from './Navigation/Navigation';

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <div className="navigationWrapper">
                <Navigation />                
            </div>
            <main>
                    {children}
            </main>
            <Footer/>
        </React.Fragment>
    );
};
export default Layout;