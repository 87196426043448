import * as React from 'react';
import { OtherProjects } from '../components/shared/OtherProjects';
import ProjectInfo from '../components/shared/ProjectInfo';

export const CruCataloguePage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "Catálogo interactivo CRU",
    ProjectAbout : "Desarrollo de catalogo interactivo para dispositivo iPad Plan Integrado de desarrollo 2012 en conjunto con +56 Diseño para la consultora CRU.",
    ProjectFeatures: {
      First : "Aplicación nativa iOS.",
      Second : "Publicación de páginas con visualización interactiva de gráficos estádisticos",
      Third : "Navegación fluida sobre contenido digital",
      Fourth : "Acceso a resumen de páginas (thumbnails)",
      Fifth : "Distribuición privada"
    },
    ProjectRef: "",
    ProjectRefTitle: "Sitio privado",
    ProjectSecondaryRef: "",
    ProjectSecondaryRefTitle: "",
    ProjectTags : "Objective-C | XCode | CocoaTouch",
    ProjectManager : {
      imageUrl: "../assets/team/cristian-sphere.png",
      imageInitials: 'CC',
      text: 'Cristián Carrión',
      secondaryText: 'Product Manager'
    }
  };
  return (
    <div>
      <div id='fullproject' className='catalogo-cru-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>
      <OtherProjects/>      
    </div>
  );
};