import * as React from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';

export const QuoteBanner: React.FunctionComponent = () => {
  return (
    <div id='fullproject' className='quote-background'>
      <Container>
        <Row>
          <Col>
              <div className='mt-5 text-white quote-transparent'>
                <p className='display-6 mt-4 mb-4'>
                  I value simplicity over everything. I always look for simplicity.
                  <br></br>
                  Simplicity is important in the quest for developer productivity.
                </p>          
                <p className='h3'>
                  &mdash; Anders Hejlsberg
                </p>
              </div>
          </Col>        
        </Row>
      </Container>
    </div>
  );
};