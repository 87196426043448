import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import "swiper/css/navigation"

import { HomePage } from './pages/HomePage';
import { StartWorkflowPage } from './pages/StartWorkflowPage'
import { AboutUsPage } from './pages/AboutUsPage'
import { WhoWeAre} from './pages/WhoWeAre'
import { CruCataloguePage } from './pages/CruCataloguePage'
import { ApoquindoPage } from './pages/ApoquindoPage'
import { ReworkMenuPage } from './pages/ReworkMenuPage'
import { VistaHomePage } from './pages/VistaHomePage'
import { ProvidenciaPage } from './pages/ProvidenciaPage'
import { ComponentTesting } from './pages/ComponentTesting'
import { CardComponents } from './pages/CardComponents';
import { OurTeamVariantPage } from './pages/OurTeamVariantPage';


import SwiperCore, {
  Navigation
} from 'swiper'
import { TeachingBubbleComponents } from './pages/TeachingBubbleComponents';

SwiperCore.use([Navigation]);

export const App: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/who-we-are" element={<WhoWeAre />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="/start-workflow" element={<StartWorkflowPage />} />
      <Route path="/catalogo-cru" element={<CruCataloguePage />} />
      <Route path="/apoquindo" element={<ApoquindoPage />} />
      <Route path="/rework-menu" element={<ReworkMenuPage />} />
      <Route path="/vista-home" element={<VistaHomePage />} />
      <Route path="/providencia" element={<ProvidenciaPage />} />
      <Route path="/components" element={<ComponentTesting/>}/>
      <Route path="/card-components" element={<CardComponents/>}/>
      <Route path="/our-team-variant" element={<OurTeamVariantPage/>}/>
      <Route path="/teaching-bubble" element={<TeachingBubbleComponents/>}/>
    </Routes>
  );
};
