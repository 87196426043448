import * as React from 'react';
import { Image } from '@fluentui/react';
import { Container, Row, Col } from 'react-bootstrap';

import providencia from '../../assets/clients/logo-mprovidencia-bw.png';
import jrconsultores from '../../assets/clients/logo-jrconsultores-bw.png';
import telecom from '../../assets/clients/logo-itelecom-bw.png';
import coasin from '../../assets/clients/logo-coasin-bw.png';
import cria from '../../assets/clients/logo-cria-bw.png';
import museo from '../../assets/clients/logo-museo-bw.png';
import rework from '../../assets/clients/logo-rework-bw.png';
import mas56 from '../../assets/clients/logo-mas56-bw.png';
import plango from '../../assets/clients/logo-plango-bw.png';

export const OurClients: React.FunctionComponent = () => {
  return (
    <Container>
      <Row className='mt-3 mb-3'>
        <Col className='text-center'>
          <h2>Nuestros clientes</h2>
        </Col>
      </Row>
      <Row className='text-center mb-5'>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={providencia} alt="logoProvidencia" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={jrconsultores} alt="logoJr" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={telecom} alt="logoTelecom" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={coasin} alt="logoCoasin" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={cria} alt="logoCria" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={museo} alt="logoMuseo" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={rework} alt="logoRework" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={mas56} alt="logoMas56" />
        </Col>
        <Col lg={3} md={6} xs={6}>
          <Image height={60} src={plango} alt="logoPlango" />
        </Col>
      </Row>
    </Container>
  );
};