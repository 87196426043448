import { Container, Row, Col, Image } from "react-bootstrap";
import Panel  from "./MyPanel";

export default function LeftImageCard(props){
    console.log(props.info.panelInfo)
    return(
        <Container>
            <Row className="mt-5">
                <Col lg={6}>
                    <Image  className="rounded" src={props.image} />
                </Col>
                <Col lg={6} className="mt-5">
                    <div className="text-center">
                        <h2>{props.info.title}</h2>
                        <hr></hr>
                        <span className="text-muted">{props.info.body}</span>
                        <br/><br/>
                        <Panel panelInfo={props.info.panelInfo} />
                        <br/><br/>
                    </div>
                </Col>
            </Row>

        </Container>

    )
}

//todo: className align-left existe?