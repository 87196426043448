import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from "./Navigation.module.css"

const Navigation = () => {
    return (
      <Navbar fixed="top" bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={NavLink} to="/">
          <img
            src="nk-logo-sm-blue.png"
            height="20"
            className={`d-inline-block ${styles.logoAlign}`}
            alt="Nimok logo"
          />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className={styles.navigationOption} as={NavLink} to="/">Home</Nav.Link>
              <Nav.Link className={styles.navigationOption} as={NavLink} to="/who-we-are">Quiénes somos</Nav.Link>
              <Nav.Link className={styles.navigationOption} as={NavLink} to="/about-us">Nuestro equipo</Nav.Link>              
            </Nav>            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
};
export default Navigation;
