import NkColumn from "../NkColumn/NkColumn"
import styles from "./NkColumnImage.module.css"
import { Image } from "react-bootstrap"

const NkColumnImage = ({ img, alt }) => {
    return(
        <NkColumn className={styles.nkColumnImgContainer}>
            <Image
                className={styles.nkColumnImg}
                src={img}
                alt={alt}
            />
        </NkColumn>
    )
}

export default NkColumnImage