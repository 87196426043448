import * as React from 'react';
import { DefaultPalette, Stack, } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import styles from './Footer.module.css'

const stackStylesDarkBackground = {
    root: {
        background: DefaultPalette.neutralDark,
        padding: 10,
    },
};

export const Footer: React.FunctionComponent = () => {
    return (
        <Stack styles={stackStylesDarkBackground} >
            <div className={styles.footerDark}>
                <footer>
                    <div className="container">
                        <div className={`row ${styles.footerRow}`}>
                            <div className='col-sm-6 col-md-4'>
                                <Image fluid src="mspartnerwhitetranscomp.png"></Image>
                            </div>                            
                            <div className={`col-sm-6 col-md-4 ${styles.item}`}>
                                <h3>Sobre nosotros</h3>
                                <ul>
                                    <li>
                                        <Link to="/who-we-are">Quiénes somos</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">Nuestro equipo</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className={`col-md-4 ${styles.item} ${styles.text}`}>
                                <h3>Nimok</h3>
                                <p>Calle Del Inca 4446 Piso 5, Santiago</p>
                                <p>contacto@nimok.com</p>
                            </div>
                            <div className={`col ${styles.item} ${styles.social}`}>
                                
                                <a href="https://www.linkedin.com/company/nimok/" target="_blank"><i className="icon ion-social-linkedin"></i>
                                </a>
                                <a href="#">
                                    <i className="icon ion-social-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <p className="copyright">Nimok © 2022</p>
                    </div>
                </footer>
            </div>
        </Stack>

    );
};