import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { App } from './App';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import Layout from './components/shared/Layout';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import "swiper/css/bundle";
import ScrollToTop from './components/shared/ScrollToTop/ScrollToTop';

initializeIcons();

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Layout>
      <App /> 
    </Layout>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
