import * as React from 'react';
import { OtherProjects } from '../components/shared/OtherProjects';
import ProjectInfo from '../components/shared/ProjectInfo';

export const ProvidenciaPage: React.FunctionComponent = () => {
  const project = {
    ProjectName : "Municipalidad de Providencia",
    ProjectAbout : "En Nimok somos responsables de dar los servicios de Soporte y Mejoras de Software a los sistemas de información de la Municipalidad de Providencia.",
    ProjectOptionalTitle : "Destacado",
    ProjectOptionalDescription : "Un importante logro desarrollado en conjunto con Secretaría Municipal fue el 100% en el cumplimiento de Transparencia Activa, de acuerdo a la evaluación del Consejo para la transparencia. Esto se logró gracias a la determinación por implementar un nuevo sistema de Transparencia Activa en un plazo de 5 meses, tiempo en el cual, el trabajo de las unidades municipales y el equipo de desarrollo de Nimok permitió lanzar un nuevo sistema de Transparencia soportadas por tecnologías Microsoft .NET MVC3, EF, LINQ y BD Oracle.",
    ProjectFeatures: {
      First : "Sistema Documental",
      Second : "Transparencia Activa y Pasiva",
      Third : "Firma Electrónica",
      Fourth : "Venta en Linea de Permisos de Circulación",
      Fifth : "Archivo Histórico"
    },
    ProjectRef: "http://www.providencia.cl/noticias/destacados/municipalidad-registra-optimo-cumplimiento-de-transparencia-activa-en-2013",
    ProjectRefTitle: "Municipalidad de Providencia optimo cumplimiento",
    ProjectSecondaryRef: "https://transparencia.providencia.cl/tact",
    ProjectSecondaryRefTitle: "Transparencia Activa Providencia",
    ProjectTags : ".NET| MVC3 | Oracle",
    ProjectManager : {
      imageUrl: "../assets/team/cristian-sphere.png",
      imageInitials: 'CC',
      text: 'Cristián Carrión',
      secondaryText: 'Product Manager'
    }
  };
  return (
    <div>
      <div id='fullproject' className='providencia-background'>
        <div></div>
      </div>
      <ProjectInfo project={project}/>
      <OtherProjects/>
    </div>
  );
};