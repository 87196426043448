import { Link } from "react-router-dom"
import { DocumentCard, DocumentCardImage, DocumentCardDetails, DocumentCardTitle, DocumentCardActivity } from "@fluentui/react"
import { ImageFit } from '@fluentui/react/lib/Image';

const cardStyles = {
    root: {
        display: 'inline-block', marginRight: 20, marginBottom: 20, width: 320, "&:hover": {
            border: "solid rgb(50, 49, 48) 1px"
        }
    },
}

const LinkedDocumentCard = props => {
    const { item } = props

    return (
        <Link to={item.to}>
            <DocumentCard
                aria-label={item.ariaLabel}
                styles={cardStyles} >
                <DocumentCardImage height={150} imageFit={ImageFit.cover} imageSrc={item.imageSrc} />
                <DocumentCardDetails>
                    <DocumentCardTitle title={item.title} shouldTruncate />
                </DocumentCardDetails>                
            </DocumentCard>
        </Link>
    )
}

export default LinkedDocumentCard